import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encode } from 'punycode';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Notification, NotificationEntityTypes, NotificationStatuses } from '../modelli/notification';
import { Anagrafica } from '../modelli/anagrafica';
import { Barca } from '../modelli/barca';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public route: string = '/notifications';


  constructor(
    public apiService: ApiService,
    private http: HttpClient,
    private router: Router
  ) {}


  public async add( notification: Notification | any ) {
    if ( !( notification instanceof Notification ) ) {
      notification = new Notification( notification );
    }
    const result = await this.apiService.post( this.route + '/add', notification.toBackend(), true );
    if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
      return [];
    }
    if ( typeof result.status === 'undefined' || result.status !== 200 ) {
      console.warn( '[NotificationsService.add] Error: error while adding a notification:', result );
      return [];
    }
    return result.data;
  }

  public async get( filters: any = {} ) {
    const result = await this.apiService.get( this.route + '/list', filters );
    if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
      return [];
    }
    if ( typeof result.status === 'undefined' || result.status !== 200 ) {
      console.warn( '[NotificationsService.get] Error: error while getting notifications:', result );
      return [];
    }
    return Notification.toNotifications( result.data );
  }

  public async getNew( filters: any = {} ) {
    filters.start_at_lower = new Date().toISOString();
    filters.status_greater = NotificationStatuses.DELETED;
    const result = await this.apiService.get( this.route + '/list', filters );
    if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
      return [];
    }
    console.log(result);
    if ( typeof result.status === 'undefined' || result.status !== 200 ) {
      console.warn( '[NotificationsService.get] Error: error while getting notifications:', result );
      return [];
    }
    return Notification.toNotifications( result.data );
  }

  public isLoggedIn() {
    return this.apiService.isLoggedIn();
  }

  public async openEdit( notification: Notification ) {
    notification.status = NotificationStatuses.READ;
    await this.update( notification );
    switch ( notification.entity.type ) {
      case NotificationEntityTypes.SHIP:
        this.router.navigate( [ '/barche', 'modifica-barca', notification.entity.id ], {
          queryParams: {
            sezione: 0
          }
        });
        return;
      case NotificationEntityTypes.CUSTOMER:
        this.router.navigate( [ '/anagrafiche', 'modifica-anagrafica', notification.entity.id ], {
          queryParams: {
            sezione: 0
          }
        });
        return;
      default:
        return;
    }
  }

  public openNotificationsPage() {
    this.router.navigate( [ '/notifications' ], {} );
  }

  public async update( notification: Notification ) {
    const result = await this.apiService.patch( this.route + '/' + notification.id, notification.toBackend(), true );
    if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
      return [];
    }
    if ( typeof result.status === 'undefined' || result.status !== 200 ) {
      console.warn( '[NotificationsService.update] Error: error while updating a notification:', result );
      return [];
    }
    return result.data;
  }

  public async updateMany( notifications: Notification[], id?: number | string, type?: string ) {
    if ( notifications === null ) {
      notifications = [];
    }
    if ( typeof id === 'undefined' ) {
      id = notifications[0].entity.id;
    }
    if ( typeof type === 'undefined' ) {
      type = notifications[0].entity.type;
    }
    let body: any = {
      entity_id: id,
      entity_type: type,
      entities: []
    };
    notifications.forEach( ( notification: Notification ) => {
      if ( !( notification instanceof Notification ) ) {
        notification = new Notification( notification );
      }
      body.entities.push( notification.toBackend() );
    });
    const result = await this.apiService.patch( this.route + '/', body, true );
    if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
      return [];
    }
    if ( typeof result.status === 'undefined' || result.status !== 200 ) {
      console.warn( '[NotificationsService.updateMany] Error: error while updating notifications:', result );
      return [];
    }
    return result.data;
  }
}
