import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, AlertController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public globals: any = {};


  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private router: Router
  ) {}

  public async toast(messaggio: string) {
    const toast = await this.toastCtrl.create({
      duration: 3000,
      message: messaggio,
      position: 'bottom'
    });
    toast.present();
  }

  public messageboxInformazioni(titolo: string, messaggio: string, callback: Function) {
    let alert = this.alertCtrl.create({
      header: titolo,
      message: messaggio,
      buttons: [
        {
          text: "OK",
          handler: () => {
            callback();
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  public messageBoxRichiesta(titolo: string, messaggio: string, pulsante1: string, pulsante2: string, callback1: Function, callback2: Function) {
    let alert = this.alertCtrl.create({
      header: titolo,
      message: messaggio,
      buttons: [
        {
          text: pulsante1,
          handler: () => {
            callback1();
          }
        },
        {
          text: pulsante2,
          handler: () => {
            callback2();
          }
        }
      ]
    }).then(res => {
      res.present();
    });  
  }

  public messageBoxRichiesta3pulsanti(titolo: string, messaggio: string, pulsante1: string, pulsante2: string, pulsante3: string, callback1: Function, callback2: Function,
                                      callback3: Function) {
    let alert = this.alertCtrl.create({
      header: titolo,
      message: messaggio,
      buttons: [
        {
          text: pulsante1,
          handler: () => {
            callback1();
          }
        },
        {
          text: pulsante2,
          handler: () => {
            callback2();
          }
        },
        {
          text: pulsante3,
          handler: () => {
            callback3();
          }
        }
      ]
    }).then(res => {
      res.present();
    });  
  }

  public inputBox(titolo: string, messaggio: string, placeholder: string, callbackConferma: Function, callbackAnnulla: Function) {
    let alert = this.alertCtrl.create({
      header: titolo,
      message: messaggio,
      inputs: [
        {
          name: 'textbox',
          placeholder: placeholder
        },
      ],
      buttons: [
        {
          text: "OK",
          handler: data => {
            callbackConferma(data.textbox);
          }
        },
        {
          text: "Annulla",
          handler: () => {
            callbackAnnulla();
          }
        }
      ]
    }).then(res => {
      res.present();
    }); 
  }

  public stringaValuta(n) {
    n = parseFloat(n);
    Math.round(n * 100) / 100;
    return n.toFixed(2).replace('.',',');    
  }
  
  public getDataItaliana(s) {
    let tokens = s.split("-");
    if (tokens.length != 3)
        return s;
    return tokens[2] + "/" + tokens[1] + "/" + tokens[0];
  }


  /**
   * METHODS V2
   */

  /**
   * @description Navigates to the specified page applying specified parameters.
   * @param {any[]} path Array of URL fragments composing the path, or the URL as litteral string.
   * @param {any} params (Optional) Parameters to pass to the page. Default: {}.
   */
  public openPage( path: any[], params: any = {} ) {
    this.router.navigate( path, {
      queryParams: params
    });
  }
}
