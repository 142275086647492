import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encode } from 'punycode';
import { Barca } from '../modelli/barca';
import { Anagrafica } from '../modelli/anagrafica';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  public apiUrl: string = 'https://barche.storiclidorama.it/api'; // 'http://montagnamarco.dyndns.org/barche/api'; // 

  constructor(private http: HttpClient, private router: Router) { 

  }

  public async caricaBarche(modello: string, tipo: string, budgetDa: number, budgetA: number, condizione: string, 
                     materiale: string, cantiere: string, lunghezzaDa: number, lunghezzaA: number, cerca: string,
                     stato: string, operazione: string, sezione: number, preferiti: string, categoria: string,
                     pagina: number): Promise<any> {

    if ( typeof cerca === 'undefined' || cerca === null || cerca === 'undefined' ) {
      cerca = '';
    }

    console.log(pagina);
  
    const url = this.apiUrl + '/barche/lista?' + 
                              '&modello=' + encodeURI(modello) + 
                              '&tipo=' + (tipo) + 
                              '&budgetDa=' + (budgetDa) + 
                              '&budgetA=' + (budgetA) + 
                              '&condizione=' + (condizione) + 
                              '&stato=' + (stato) + 
                              '&operazione=' + (operazione) + 
                              '&materiale=' + (materiale) + 
                              '&cantiere=' + (cantiere) + 
                              '&sezione=' + (sezione) + 
                              '&evidenza=' + preferiti +
                              '&lunghezzaDa=' + (lunghezzaDa) + 
                              '&lunghezzaA=' + (lunghezzaA) + 
                              '&categoria=' + categoria +
                              '&cerca=' + encodeURI(cerca) +
                              '&pagina=' + pagina;
    try {
      const risultato = await this.http.get(url).toPromise();
      return risultato;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return null;
    }
  }

  public async caricaBarca(id: string) : Promise<any> {
    const url = this.apiUrl + '/barche/modifica/' + id; console.log(url);
    try {
      const risultato = await this.http.get(url).toPromise(); console.log(risultato);
      return risultato;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async cancellaBarca(b: Barca) : Promise<boolean> {
    const url = this.apiUrl + '/barche/cancella/' + b.id.toString();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato = await this.http.delete(url, { headers: headers }).toPromise();
      return true;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async ripristinaBarca(b: Barca) : Promise<boolean> {
    const url = this.apiUrl + '/barche/ripristina/' + b.id.toString();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato = await this.http.post(url, {}, { headers: headers }).toPromise();
      return true;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async archiviaBarca(b: Barca) : Promise<boolean> {
    const url = this.apiUrl + '/barche/archivia/' + b.id.toString();

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato = await this.http.put(url, {}, { headers: headers }).toPromise();
      return true;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async salvaBarca(id: string, barca: Barca) : Promise<boolean> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });
    console.log(id);
    console.log(barca);
    try {
      if (id === '') {
        const url = this.apiUrl + '/barche/inserisci';
        console.log(url);
        const risultato = await this.http.post(url, barca, { headers: headers }).toPromise();
        console.log(risultato);
        return true;
      } else {
        const url = this.apiUrl + '/barche/aggiorna/' + id;
        console.log(url);
        const risultato = await this.http.put(url, barca, { headers: headers }).toPromise();
        console.log(risultato);
        return true;
      }
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async caricaAnagrafiche(ragioneSociale: string, partitaIva: string, codiceFiscale: string,
                                 indirizzo: string, cap: string, citta: string, provincia: string,
                                 telefono: string, indirizzoEmail: string, operazione: string, cantiere: string,
                                 tipo: string,
                                 macrotype: string,
                                 cerca: string,
                                 pagina: number): Promise<any> {
    
    if ( typeof cerca === 'undefined' || cerca === null || cerca === 'undefined' ) {
      cerca = '';
    }
                                   
    const url = this.apiUrl + '/anagrafiche?' + 
              '&ragioneSociale=' + encodeURI(ragioneSociale) + 
              '&partitaIva=' + encodeURI(partitaIva) + 
              '&codiceFiscale=' + encodeURI(codiceFiscale) + 
              '&indirizzo=' + encodeURI(indirizzo) + 
              '&cap=' + encodeURI(cap) + 
              '&citta=' + encodeURI(citta) + 
              '&tipo=' + encodeURI(tipo) + 
              '&macrotype=' + encodeURI(macrotype) + 
              '&provincia=' + encodeURI(provincia) + 
              '&operazione=' + encodeURI(operazione) + 
              '&telefono=' + encodeURI(telefono) + 
              '&indirizzoEmail=' + encodeURI(indirizzoEmail) +
              '&cantiere=' + encodeURI(cantiere) +
              '&cerca=' + encodeURI(cerca) +
              '&pagina=' + pagina;

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    console.log(url);

    try {
      const risultato = await this.http.get(url, { 
        headers: headers
      }).toPromise();
      return risultato;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return null;
    }
  }

  public async cancellaAnagrafica(a: Anagrafica) : Promise<boolean> {
    const url = this.apiUrl + '/anagrafiche/' + a.id.toString();
    
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato = await this.http.delete(url, { headers: headers }).toPromise();
      return true;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async salvaAnagrafica(id: string, anagrafica: Anagrafica) : Promise<boolean> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      if (id == '') {
        const url = this.apiUrl + '/anagrafiche';
        const risultato = await this.http.post(url, anagrafica, { headers: headers }).toPromise();
        return true;
      } else {
        const url = this.apiUrl + '/anagrafiche/' + id;
        const risultato = await this.http.put(url, anagrafica, { headers: headers }).toPromise();
        return true;
      }
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async caricaCantieri() : Promise<any> {
    const url = this.apiUrl + '/cantieri';

    try {
      const risultato = await this.http.get(url).toPromise();
      return risultato;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async caricaAnagrafica(id: string) : Promise<any> {
    const url = this.apiUrl + '/anagrafiche/' + id;
    
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato = await this.http.get(url, { headers: headers }).toPromise();
      return risultato;
    } catch (e) {
      if (e.status == 401) {
        this.router.navigate(['/login']);
        return;
      }
      console.log(e);
      return false;
    }
  }

  public async caricaProvince() : Promise<any> {
    const url = this.apiUrl + '/tabelle/province';

    try {
      const risultato = await this.http.get(url).toPromise();
      return risultato;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async caricaNazioni() : Promise<any> {
    const url = this.apiUrl + '/tabelle/nazioni';

    try {
      const risultato = await this.http.get(url).toPromise();
      return risultato;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async login(email: string, password: string) : Promise<boolean> {
    const url = this.apiUrl + '/auth/login';
      
    try {
      const risultato: any = await this.http.post(url, {
        username: email,
        password: password
      }).toPromise();

      console.log(risultato);

      localStorage.setItem('idUtente', risultato.id);
      localStorage.setItem('nome', risultato.nome);
      localStorage.setItem('cognome', risultato.cognome);
      localStorage.setItem('indirizzoEmail', risultato.email);
      localStorage.setItem('token', risultato.token);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public async condividi(idAnagrafica: number, barche: Array<number>) : Promise<Array<string>> {
    const url = this.apiUrl + '/barche/condividi';
     
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato: any = await this.http.post(url, {
        idAnagrafica: idAnagrafica,
        barche: barche
      },
      { headers: headers }).toPromise();

      console.log(risultato);

      return risultato;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async condividiEmail(idAnagrafica: number, barche: Array<string>, messaggio: string) : Promise<boolean> {
    const url = this.apiUrl + '/barche/condividi-email';
     
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    try {
      const risultato: any = await this.http.post(url, {
        idAnagrafica: idAnagrafica,
        barche: barche,
        messaggio: messaggio
      },
      { headers: headers }).toPromise();

      console.log(risultato);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('idUtente');
    localStorage.removeItem('nome');
    localStorage.removeItem('cognome');
    localStorage.removeItem('indirizzoEmail');
  }

  public isLoggedIn() : boolean {
    return localStorage.getItem('token') !==  null;
  }
}
