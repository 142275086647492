import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DatabaseService } from './servizi/database.service';
import { Router } from '@angular/router';
import { UtilsService } from './servizi/utils.service';
import { NotificationsService } from './servizi/notifications.service';
import { Notification } from './modelli/notification';
import { ShipsService } from './servizi/ships.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public utente: string = '';
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Riepilogo',
      url: '/dashboard',
      icon: 'home',
      login: true
    },
    {
      title: 'Barche',
      url: '/barche',
      icon: 'boat',
      login: false
    },
    {
      title: 'Archivio barche',
      url: '/barche/archivio',
      icon: 'folder',
      login: true
    },
    {
      title: 'Cestino barche',
      url: '/barche/cestino',
      icon: 'trash',
      login: true
    },
    {
      title: 'Richieste di acquisto',
      url: '/barche/acquisto',
      icon: 'cart',
      login: true
    },
    {
      title: 'Anagrafiche',
      url: '/anagrafiche',
      icon: 'bookmarks',
      login: true,
      subitems: [{
        title: 'Clienti',
        url: '/anagrafiche/clienti',
        icon: 'person'
      },
      {
        title: 'Partner',
        url: '/anagrafiche/partner',
        icon: 'business'
      }]
    },
  ];
 
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public db: DatabaseService,
    public notificationsService: NotificationsService,
    private router: Router,
    private shipsService: ShipsService,
    private utils: UtilsService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then( async() => {
      await this.shipsService.getWorksites();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.utente = localStorage.getItem('cognome') + ' ' + localStorage.getItem('nome');
  }

  login() {
    this.router.navigate(['/login']);
  }

  async logout() {
    this.utils.messageBoxRichiesta('Conferma', 'Vuoi davvero uscire?', 'Sì', 'No', 
                                   async function() {
                                    await this.db.logout();
                                    this.router.navigate(['/login']);
                                   }.bind(this),
                                   function() {});
  }
}
