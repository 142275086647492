import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encode } from 'punycode';
import { Barca, ShipOperations, ShipStatuses } from '../modelli/barca';
import { Anagrafica } from '../modelli/anagrafica';
import { Router } from '@angular/router';
import { ApiService } from './api.service';


export enum ShipFilters {
  FEATURED = 'featured',
  NEGOTIATIONS = 'negotiations',
  NOTIFICATIONS = 'notifications',
  LATEST = 'latest',
  REFITTINGS = 'refittings',
  REQUESTS = 'requests'
};

export interface Worksite {
  id: number;
  descrizione: string;
};


@Injectable({
  providedIn: 'root'
})
export class ShipsService {
  public route: string = '/ships';
  public filterPresets: { [key: string]: any } = {};
  public worksites: Worksite[] = [];


  constructor(
    public apiService: ApiService,
    private http: HttpClient,
    private router: Router
  ) {
    this.filterPresets[ ShipFilters.FEATURED ] = { evidenza: true };
    this.filterPresets[ ShipFilters.NEGOTIATIONS ] = { _join: 'customers', _join_type: 'LEFT', operazione: ShipOperations.NEGOTIATION, stato_lower: ShipStatuses.CLOSED };
    this.filterPresets[ ShipFilters.NOTIFICATIONS ] = { _join: 'customers', evidenza: true };
    this.filterPresets[ ShipFilters.LATEST ] = { _order: 'data_inserimento', _order_dir: 'DESC' };
    this.filterPresets[ ShipFilters.REFITTINGS ] = { _join: 'customers', _join_type: 'LEFT', operazione: ShipOperations.REFITTING };
    this.filterPresets[ ShipFilters.REQUESTS ] = { _join: 'customers', operazione: ShipOperations.WISH };
  }


  public async get( filters: any | ShipFilters = {} ) {
    if ( typeof filters === 'string' ) {
      filters = this.toFilters( filters );
    }
    const result = await this.apiService.get( this.route, filters );
    if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
      return [];
    }
    if ( typeof result.status === 'undefined' || result.status !== 200 ) {
      console.warn( '[ShipsService.get] Error: error while getting ships:', result );
      return [];
    }
    return Barca.toShips( result.data );
  }

  public async getFavorites( filters: any = {} ) {
    filters.evidenza = true;
    return await this.get( filters );
  }

  public async getWishes( filters: any = {} ) {
    filters.operazione = ShipOperations.WISH;
    return await this.get( filters );
  }

  public getWorksiteNameById( id: number ) {
    for ( let i = 0; i < this.worksites.length; i++ ) {
      if ( this.worksites[i].id === id ) {
        return this.worksites[i].descrizione;
      }
    }
    return '';
  }

  public async getWorksites() : Promise<any> {
    const worksites: any = await this.apiService.get( '/cantieri' );
    if (
      typeof worksites === 'undefined' ||
      worksites === null ||
      typeof worksites.righe === 'undefined' ||
      worksites.righe === null ||
      !Array.isArray( worksites.righe )
    ) {
      this.worksites = [];
    } else {
      this.worksites = worksites.righe;
    }
    return this.worksites;
  }

  public isLoggedIn() {
    return this.apiService.isLoggedIn();
  }

  public openEdit( id: number | string, mode: number = 0 ) {
    this.router.navigate( [ '/barche', 'modifica-barca', id ], {
      queryParams: {
        sezione: mode
      }
    });
  }

  public toFilters( filterType: ShipFilters | string ) {
    const filterPreset: any = this.filterPresets[ filterType ];
    if ( typeof filterPreset === 'undefined' || filterPreset === null ) {
      return {};
    }
    return filterPreset;
  }
}
