import { Anagrafica } from './anagrafica';


export enum ShipCategories {
    NONE = 'Nessuna',
    BROKER = 'Broker',
    PRIVATE = 'Privati',
    REQUESTS = 'Richieste private',
    WORKSITE = 'Cantiere',
};

export interface ShipCustomer {
    id: number;
    email: string;
    name: string;
    phone: string;
    status: ShipCustomerStatus;
};

export enum ShipCustomerStatus {
    NONE = 0,
    CLOSED = 1,
    OPENED = 2
};

export enum ShipOperations {
    SELL = 0,
    REFITTING = 1,
    STORAGE = 2,
    WISH = 3,
    NEGOTIATION = 4
};

export enum ShipStatuses {
    OPEN = 0,
    PENDING = 1,
    CLOSED = 2
};

export enum ShipWorks {
    NONE = 'Nessuna',
    SUBSTITUTIONS = 'Sostituzione pezzi',
    REPAIRINGS = 'Riparazioni',
    VOUCHERS = 'Tagliandi',
    STORAGES = 'Invernaggi/svernaggi'
};

export const shipCategories: ShipCategories[] = [
    ShipCategories.NONE,
    ShipCategories.BROKER,
    ShipCategories.PRIVATE,
    ShipCategories.REQUESTS,
    ShipCategories.WORKSITE
];

export const shipWorks: ShipWorks[] = [
    ShipWorks.NONE,
    ShipWorks.SUBSTITUTIONS,
    ShipWorks.REPAIRINGS,
    ShipWorks.VOUCHERS,
    ShipWorks.STORAGES
];


export class Barca {
    public id: number;
    public tipo: number;
    public condizione: number;
    public cantiere: number;
    public modello: string;
    public matricolaScafo: string;
    public matricolaMotore: string;
    public motore: string;
    public prezzoAcquisto: number;
    public prezzoVendita: number;
    public larghezza: number;
    public lunghezza: number;
    public annoCostruzione: number;
    public materiale: number;
    public persone: number;
    public caratteristiche: string;
    public fotoDaCaricare: Array<any> = new Array();
    public foto: Array<any> = new Array();
    public thumb: string;
    public descrizioneCantiere: string;
    public operazione: number;
    public stato: number;
    public evidenza: number;
    public anagrafica: Anagrafica;
    public anagrafiche: Array<any> = new Array();
    public oreMotore: number;
    public note: string;
    public categoria: string;
    public builder: string;
    public creation_year: string;
    public horse_power: number;
    public hours: string;
    public length: number;
    public width: number;
    public draught: string;
    public weight: string;
    public works: any;
    public certificate_creation: string;
    public certificate_expiration: string;
    public fuel: number;
    public for_sale: boolean;
    public author: number;
    public dotations: string;

    public name: string;
    public customer: ShipCustomer = {
        id: -1,
        name: '',
        email: '',
        phone: '',
        status: ShipCustomerStatus.NONE
    };


    public constructor() {
    }


    public static toBarca( data: any ): Barca {
        let ship = new Barca();
        ship.id = data.id;
        ship.tipo = parseInt( data.tipo );
        ship.condizione = parseInt( data.condizione );
        ship.cantiere = parseInt( data.cantiere );
        ship.modello = data.modello;
        ship.matricolaScafo = data.matricolaScafo;
        ship.matricolaMotore = data.matricolaMotore;
        ship.motore = data.motore;
        ship.prezzoAcquisto = data.prezzoAcquisto;
        ship.prezzoVendita = data.prezzoVendita;
        ship.larghezza = data.larghezza;
        ship.lunghezza = data.lunghezza;
        ship.annoCostruzione = data.annoCostruzione;
        ship.materiale = parseInt( data.materiale );
        ship.persone = data.persone;
        ship.caratteristiche = data.caratteristiche;
        ship.fotoDaCaricare = data.fotoDaCaricare;
        ship.foto = data.foto;
        ship.thumb = data.thumb;
        ship.descrizioneCantiere = data.descrizioneCantiere;
        ship.operazione = data.operazione;
        ship.stato = parseInt( data.stato );
        ship.evidenza = data.evidenza;
        ship.anagrafica = data.anagrafica;
        ship.anagrafiche = data.anagrafiche;
        ship.oreMotore = data.oreMotore;
        ship.note = data.note;
        ship.categoria = data.categoria;
        ship.builder = data.builder;
        ship.creation_year = data.creation_year;
        ship.horse_power = data.horse_power;
        ship.hours = data.hours;
        ship.length = data.length;
        ship.width = data.width;
        ship.draught = data.draught;
        ship.weight = data.weight;
        ship.works = parseInt( data.works );
        ship.certificate_creation = data.certificate_creation;
        ship.certificate_expiration = data.certificate_expiration;
        ship.fuel = data.fuel;
        ship.for_sale = data.for_sale === 'true' || data.for_sale === '1' || data.for_sale === 1 || data.for_sale === true;
        ship.author = data.author;
        ship.name = data.modello;
        if ( typeof data.customer_id !== 'undefined' && data.customer_id !== null ) {
            ship.customer = {
                id: data.customer_id,
                email: data.customer_email,
                name: data.customer_name,
                phone: data.customer_phone,
                status: parseInt( data.customer_status )
            };
        }
        return ship;
    }

    public static toBarche( data: any[] ) {
        let ships: Barca[] = [];
        for ( let i = 0; i < data.length; i++ ) {
            ships.push( Barca.toBarca( data[i] ) );
        }
        return ships;
    }

    public static toShip( data: any[] ) {
        return Barca.toBarca( data );
    }

    public static toShips( data: any[] ) {
        return Barca.toBarche( data );
    }
}
