export interface NotificationEntity {
    id: number;
    image?: string;
    name: string;
    notes?: string;
    type: string;
};

export enum NotificationEntityTypes {
    CUSTOMER = 'anagrafiche',
    SHIP = 'barche',
    UNKNOWN = 'unknown'
};

export enum NotificationStatuses {
    DELETED = 0,
    NEW = 1,
    READ = 2,
    FAVORITE = 3,
    EXPIRED = 4
};


export class Notification {
    public id: number;
    public author: number;
    public createdAt: Date | null;
    public expireAt: Date | null;
    public startAt: Date | null;
    public title: string;
    public description: string;
    public image: string;
    public data: any | null;
    public status: NotificationStatuses;
    public entity: NotificationEntity;
    // UI fields
    public startAtString: string;

    public static defaultExpire: { date: Date, text: string } = {
        date: new Date( '3000-01-01' ),
        text: '3000-01-01T00:00:00.000Z'
    };


    public constructor( data?: any ) {
        if ( typeof data === 'undefined' || data === null ) {
            this.id = new Date().getTime();
            this.author = -1;
            this.createdAt = new Date();
            this.expireAt = Notification.defaultExpire.date;
            this.startAt = new Date();
            this.title = '';
            this.description = '';
            this.image = 'https://barche.storiclidorama.it/resources/storic-lidorama-notification-placeholder.jpg';
            this.data = {};
            this.status = NotificationStatuses.NEW;
            this.entity = null;
        } else {
            this.id = data.id || -1;
            this.author = data.author || -1;
            this.createdAt = ( typeof data.created_at !== 'undefined' ? new Date( data.created_at ) : new Date() );
            this.expireAt = ( typeof data.expire_at !== 'undefined' ? new Date( data.expire_at ) : Notification.defaultExpire.date );
            this.startAt = ( typeof data.start_at !== 'undefined' ? new Date( data.start_at ) : new Date( new Date().getTime() + 604800000 ) );
            this.title = data.title || '';
            this.description = data.description || '';
            this.image = data.image || 'https://barche.storiclidorama.it/resources/storic-lidorama-notification-placeholder.jpg';
            const hasData: boolean = typeof data.data !== 'undefined' && data.data !== '';
            this.data = ( !hasData ? {} : JSON.parse( data.data ) );
            if ( typeof data.status === 'undefined' || data.status === null ) {
                this.status = NotificationStatuses.NEW;
            } else {
                this.status = parseInt( data.status );
            }
            this.entity = {
                id: -1,
                type: '',
                name: ''
            };
            if ( typeof data.entity !== 'undefined' && data.entity !== null ) {
                this.entity = { ...this.entity, ...data.entity };
                if ( typeof data.entity.name === 'undefined' || data.entity.name === '' ) {
                    this.entity.name = 'Tipologia: ' + data.entity_type;
                } else {
                    this.entity.name = data.entity.name;
                }
            } else if ( hasData && typeof this.data.entityName !== 'undefined' ) {
                this.entity.name = this.data.entityName;
            } else {
                this.entity.name = 'Tipo: ' + data.entity_type;
            }
            if ( typeof data.entity_id !== 'undefined' && data.entity_id !== null && typeof data.entity_type !== 'undefined' ) {
                this.entity.id = data.entity_id;
                this.entity.type = data.entity_type;
            }
        }
        if ( new Date().getTime() > this.expireAt.getTime() ) {
            this.status = NotificationStatuses.EXPIRED;
        }
        this.startAtString = this.startAt.toISOString();
    }


    public toBackend() {
        let startAt: string = '';
        if ( typeof this.startAtString !== 'undefined' && this.startAtString !== null && this.startAtString !== '' ) {
            startAt = this.startAtString;
        } else {
            startAt = this.startAt.toISOString();
        }
        return {
            id: this.id,
            author: this.author,
            created_at: this.createdAt.toISOString(),
            expire_at: this.expireAt.toISOString(),
            start_at: startAt,
            status: this.status,
            title: this.title,
            description: this.description,
            image: this.image,
            data: JSON.stringify( this.data ),
            entity_type: this.entity.type,
            entity_id: this.entity.id
        };
    }


    public static toNotification( data: any ): Notification {
        return new Notification( data );
    }

    public static toNotifications( data: any[] ) {
        let notifications: Notification[] = [];
        for ( let i = 0; i < data.length; i++ ) {
            notifications.push( Notification.toNotification( data[i] ) );
        }
        return notifications;
    }
}
