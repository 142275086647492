import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './servizi/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'barche',
    loadChildren: () => import('./pagine/barche/barche.module').then( m => m.BarchePageModule)
  },
  {
    path: 'anagrafiche',
    loadChildren: () => import('./pagine/anagrafiche/anagrafiche.module').then( m => m.AnagrafichePageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pagine/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'seleziona-barca',
    loadChildren: () => import('./pagine/seleziona-barca/seleziona-barca.module').then( m => m.SelezionaBarcaPageModule)
  },
  {
    path: 'zoom-foto',
    loadChildren: () => import('./pagine/zoom-foto/zoom-foto.module').then( m => m.ZoomFotoPageModule)
  },
  {
    path: 'condividi-barche',
    loadChildren: () => import('./pagine/condividi-barche/condividi-barche.module').then( m => m.CondividiBarchePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pagine/dashboard/dashboard.module').then( m => m.DashboardPageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pagine/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'ships',
    loadChildren: () => import('./pagine/ships/ships.module').then( m => m.ShipsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
