import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
    public url: string = 'https://barche.storiclidorama.it/api';


    constructor(
        private http: HttpClient,
        private router: Router
    ) {}

    public encode( params = {} ): string {
        let query = '';
        for( const key in params ) {
            if ( params.hasOwnProperty( key ) ) {
                query += '&' + key + '=' + encodeURI( params[ key ] );
            }
        }
        if ( query.startsWith( '&' ) ) {
            query = '?' + query.substr( 1 );
        }
        return query;
    }

    public async get( endpoint: string, params: any = {}, auth: boolean = false ) : Promise<any> {
        if ( !endpoint.startsWith( '/' ) ) {
            endpoint = '/' + endpoint;
        }
        let headers = {};
        if ( auth ) {
            headers = { headers: this.getHeaders() };
        }
        const url = this.url + endpoint + this.encode( params );
        try {
            const result = await this.http.get( url, headers ).toPromise();
            return result;
        } catch ( error ) {
            return this.handleError( endpoint, 'get', params, error );
        }
    }

    public getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.getToken()
        });
    }

    public getToken(): string | null {
        const token: string = localStorage.getItem( 'token' );
        if ( typeof token === 'undefined' || token === null ) {
            this.router.navigate(['/login']);
            return null;
        }
        return token;
    }

    public handleError( endpoint: string, method: string, params: any, error: any ): boolean {
        if ( error.status == 401 ) {
            this.router.navigate( [ '/login' ] );
            return false;
        }
        console.warn( '[ApiService.handleError] Error: error while trying to send a request.' );
        console.warn( '=> Endpoint:', endpoint );
        console.warn( '=> Method:', method );
        console.warn( '=> Parameters:', params );
        console.warn( '=> Error:', error );
        return false;
    }

    public isLoggedIn(): boolean {
        const token = localStorage.getItem( 'token' );
        return typeof token !== 'undefined' && token !== null;
    }

    public async login( email: string, password: string ): Promise<boolean> {
        const url = this.url + '/auth/login';
        try {
            const result: any = await this.http.post(url, {
                username: email,
                password: password
            }).toPromise();

            localStorage.setItem( 'idUtente', result.id );
            localStorage.setItem( 'nome', result.nome );
            localStorage.setItem( 'cognome', result.cognome );
            localStorage.setItem( 'indirizzoEmail', result.email );
            localStorage.setItem( 'token', result.token );

            return true;
        } catch ( error ) {
            console.warn( '[ApiService.login] Error: error while trying to login:', error );
            return false;
        }
    }

    public logout() {
        localStorage.removeItem( 'token' );
        localStorage.removeItem( 'idUtente' );
        localStorage.removeItem( 'nome' );
        localStorage.removeItem( 'cognome' );
        localStorage.removeItem( 'indirizzoEmail' );
    }

    public async patch( endpoint: string, body: any = {}, auth: boolean = false ) : Promise<any> {
        if ( !endpoint.startsWith( '/' ) ) {
            endpoint = '/' + endpoint;
        }
        const url = this.url + endpoint;
        let headers = {};
        if ( auth ) {
            headers = { headers: this.getHeaders() };
        }
        try {
            const result = await this.http.patch( url, body, headers ).toPromise();
            return result;
        } catch ( error ) {
            return this.handleError( endpoint, 'patch', body, error );
        }
    }

    public async post( endpoint: string, body: any = {}, auth: boolean = false ) : Promise<any> {
        if ( !endpoint.startsWith( '/' ) ) {
            endpoint = '/' + endpoint;
        }
        const url = this.url + endpoint;
        let headers = {};
        if ( auth ) {
            headers = { headers: this.getHeaders() };
        }
        try {
            const result = await this.http.post( url, body, headers ).toPromise();
            return result;
        } catch ( error ) {
            return this.handleError( endpoint, 'post', body, error );
        }
    }

    public async sendMail( selecteds: any[], message: string ) {
        let addresses: string[] = [];
        for ( let i = 0; i < selecteds.length; i++ ) {
            addresses.push( selecteds[i].indirizzoEmail );
        }
        const body: any = {
            addresses,
            message
        }
        const result = await this.post( 'customers/mail', body );
        if ( typeof result === 'undefined' || result === null || typeof result.data === 'undefined' ) {
            return [];
        }
        if ( typeof result.status === 'undefined' || result.status !== 200 ) {
            console.warn( '[ApiService.sendMail] Error: error while sending:', result );
            return [];
        }
        return result.data;
    }
}
